import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  marker: any = {
    position: {
      lat: 49.426920,
      lng: 8.530245,
    },
  };
  center: any = {
    lat: 49.426920,
    lng: 8.530245,
  };

  constructor() { }

  ngOnInit(): void {
  }

}
