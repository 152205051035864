<div class="header-image">
  <nav class="navbar transparent navbar-expand-lg fixed-top" (scroll)="onWindowScroll($event);">
    <div class="container">
      <a class="navbar-brand mb-0 h1" href="#">Kathi & Max</a>
      <button class="navbar-toggler" (click)="navbarToggleClick()" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <div class="animated-icon2"><span></span><span></span><span></span><span></span></div>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" pageScroll href="#faq" [pageScrollOffset]="75" [pageScrollInterruptible]="false" [pageScrollDuration]="500">Hochzeits FAQ</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" pageScroll href="#location" [pageScrollOffset]="75" [pageScrollInterruptible]="false" [pageScrollDuration]="500">Location</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" pageScroll href="#wedding-day" [pageScrollOffset]="75" [pageScrollInterruptible]="false" [pageScrollDuration]="500">Tag der Hochzeit</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" pageScroll href="#contact" [pageScrollOffset]="75" [pageScrollInterruptible]="false" [pageScrollDuration]="500">Kontakt</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="svg-divider">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200"><path fill-opacity="1" d="M0,128L30,144C60,160,120,192,180,186.7C240,181,300,139,360,112C420,85,480,75,540,90.7C600,107,660,149,720,170.7C780,192,840,192,900,186.7C960,181,1020,171,1080,144C1140,117,1200,75,1260,53.3C1320,32,1380,32,1410,32L1440,32L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg>
  </div>
</div>
