<google-map
    width="100%"
    height="300px"
    [center]="center">
    <map-marker
        [position]="marker.position"
        [label]="marker.label"
        [title]="marker.title"
        [options]="marker.options"
    >
    </map-marker>
</google-map>
