<div class="wrapper">
  <div class="container">
    <h2 class="section-title">Hochzeits FAQ</h2>
    <ngb-accordion
            #acc="ngbAccordion"
            activeIds="ngb-panel-0"
            closeOthers="true"
            >
      <ngb-panel *ngFor="let faq of faqs" title="{{faq.question}}">
        <ng-template ngbPanelContent>
          {{faq.answer}}
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <div class="faq-accordion"></div>
  </div>
</div>
<div>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 128"><path fill-opacity="1" d="M0,128L1440,0L1440,0L0,0Z"></path></svg>
</div>

