<div class="wrapper">
    <div class="container">
        <h2 class="section-title">Kontakt</h2>
        <div>
            <img src="assets/images/profile.jpg" />
        </div>
        <p>Katharina & Max Stupka</p>
        <p> Siemensstraße 10 <br> 68526 Ladenburg</p>
        <div>
            <fa-icon [icon]="faMobileAlt" style="margin-right: 8px"></fa-icon>
            <a href="tel:+4915759608142">0157/59608142</a>
        </div>
        <div>
            <fa-icon [icon]="faEnvelope" style="margin-right: 8px"></fa-icon>
            <a href="mailto:hochzeit@kathiundmax.de">hochzeit@kathiundmax.de</a>
        </div>
    </div>
</div>