import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { WeddingFaqComponent } from './wedding-faq/wedding-faq.component';
import { LocationMapComponent } from './location-map/location-map.component';
import {GoogleMapsModule} from '@angular/google-maps';
import { FooterComponent } from './footer/footer.component';
import { CountdownComponent } from './countdown/countdown.component';
import { LocationComponent } from './location/location.component';
import { WeddingDayComponent } from './wedding-day/wedding-day.component';
import { ContactComponent } from './contact/contact.component';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    WeddingFaqComponent,
    LocationMapComponent,
    FooterComponent,
    CountdownComponent,
    LocationComponent,
    WeddingDayComponent,
    ContactComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        GoogleMapsModule,
        NgbModule,
        NgxPageScrollModule,
        FontAwesomeModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
