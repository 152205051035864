import { Component } from '@angular/core';
import {IFaq} from '../../api/interfaces/faq.interface';

@Component({
  selector: 'app-wedding-faq',
  templateUrl: './wedding-faq.component.html',
  styleUrls: ['./wedding-faq.component.scss']
})
export class WeddingFaqComponent {
  faqs: IFaq[] = [
      /*TODO: Zwinker Smiley in echt einfügen*/
    {
      question: 'Wann soll ich wo sein?',
      answer: `Die freie Trauung findet am 23.10.2021 um 16:00 Uhr in der Bokeria in Mannheim statt. Ein bisschen früher da zu sein schadet aber nicht. 😉`
    },
    {
      question: 'Wo kann ich parken?',
      answer: 'Neben der Bokeria befindet sich ein großer Parkplatz mit ausreichend Stellplätzen.'
    },
    {
      question: 'Was soll ich anziehen?',
      answer: 'Wir freuen uns auf deine schönste Abendgarderobe.'
    },
    {
      question: 'Habt ihr ein Thema für die Hochzeit?',
      answer: 'Building our love ... brick by brick - Das kleine Geschenk in der Einladung könnte ein Hinweis sein. 😊'
    },
    {
      question: 'Wo kann ich übernachten?',
      answer: 'Wenn du nach der Feier nicht mehr nach Hause fahren möchtest (oder kannst 😉) und Probleme hast ein Hotel in Mannheim zu finden' +
          ', schreib uns eine E-Mail.'
    },
    {
      question: 'Was ist, wenn ich nach der Feier mein Auto stehen lassen möchte (muss)?',
      answer: 'Du kannst das Personal der Bokeria jederzeit ansprechen, diese werden ein Taxi (auf eigene Kosten) für dich organisieren.'
    },
    {
      question: 'Bis wann muss ich Bescheid sagen, ob ich komme?',
      answer: 'Wir freuen uns auf deine Rückmeldung bis zum 31.08.2021.'
    },
    {
      question: 'Was ist, wenn ich Allergien und/oder Unverträglichkeiten habe?',
      answer: 'Gib uns bitte rechtzeitig Bescheid, damit wir dies an die Bokeria weitergeben können. Da wir ein Buffet anbieten, sollte für jeden etwas dabei sein.'
    },
    {
      question: 'Was ist, wenn ich eine Idee habe, von der das Hochzeitspaar nichts wissen darf?',
      answer: 'Da wir uns ein schönes Abendprogramm für euch überlegt haben, bitten wir dich von (peinlichen) Überraschungen abzusehen. 😊 '
    },
    {
      question: 'Kann ich Fotos während der freien Trauung machen?',
      answer: 'Wir würden uns freuen, wenn du den schönen Moment mit uns genießt und wir auch in eure Gesichter sehen können. Ein Fotograf begleitet uns, ' +
          'der die schönen Momente einfängt. Eine Auswahl der Bilder wird danach auf dieser Webseite hochgeladen.'
    },
    {
      question: 'Ich würde euch gerne etwas zur Hochzeit schenken, habt ihr einen Wunsch?',
      answer: 'Wir freuen uns auf Geschenke in jeglicher Währung.'
    },
    {
      question: 'Was muss ich noch unbedingt wissen?',
      answer: 'Wir bitten euch auf Konfetti, Wunderkerzen, Feuerwerk oder Ähnliches zu verzichten.'
    },
  ];

}
