import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit {

  private endDate: Date = new Date(2021, 10, 23, 15);

  difference: number;
  days = 0;
  hours = 0;
  minutes = 0;
  seconds = 0;

  constructor() {

    interval().subscribe((x) => {
      this.difference = Math.floor((this.endDate.getTime() - new Date().getTime()) / 1000);
      this.days = this.getDays(this.difference) >= 0 ? this.getDays(this.difference) : 0;
      this.hours = this.getDays(this.difference) >= 0 ? this.getHours(this.difference) : 0;
      this.minutes = this.getDays(this.difference) >= 0 ? this.getMinutes(this.difference) : 0;
      this.seconds = this.getDays(this.difference) >= 0 ? this.getSeconds(this.difference) : 0;
    });
  }

  ngOnInit(): void {
  }

  getDays(t: number): number {
    return Math.floor(t / 86400) - 31;
  }

  getHours(t: number): number {
    const days = Math.floor(t / 86400);
    t -= days * 86400;
    const hours = Math.floor(t / 3600) % 24;
    return hours;
  }

  getMinutes(t: number): number {
    const days = Math.floor(t / 86400);
    t -= days * 86400;
    const hours = Math.floor(t / 3600) % 24;
    t -= hours * 3600;
    const minutes = Math.floor(t / 60) % 60;
    return minutes;
  }

  getSeconds(t: number): number {
    const days = Math.floor(t / 86400);
    t -= days * 86400;
    const hours = Math.floor(t / 3600) % 24;
    t -= hours * 3600;
    const minutes = Math.floor(t / 60) % 60;
    t -= minutes * 60;
    const seconds = t % 60;
    return seconds;
  }

}
