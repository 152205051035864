import {Component, HostListener, OnInit, Output, EventEmitter} from '@angular/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e): void {
    const toggleSpan = document.querySelectorAll('.animated-icon2 span');
    const navbar = document.querySelector('.navbar');
    if (window.pageYOffset > navbar.clientHeight) {
      navbar.classList.add('navbar-inverse');
      toggleSpan.forEach(item => {
        item.classList.remove('white');
        item.classList.add('black');
      });
    } else {
      navbar.classList.remove('navbar-inverse');
      toggleSpan.forEach(item => {
        item.classList.remove('black');
        item.classList.add('white');
      });
    }
  }

  navbarToggleClick(): void {
    const element = document.querySelector('.animated-icon2');
    if (element.classList.contains('open')) {
      element.classList.remove('open');
    } else {
      element.classList.add('open');
    }
  }
}
