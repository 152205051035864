import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss']
})

export class LocationMapComponent implements OnInit {
  marker: any = {
      position: {
        lat: 49.426920,
        lng: 8.530245,
      },
  };
  center: any = {
      lat: 49.426920,
      lng: 8.530245,
  };

  constructor() { }

  ngOnInit(): void {
  }

}
