<div class="wrapper">
    <div class="container">
        <h2 class="section-title">Tag der Hochzeit</h2>

        <div class="row">
            <div class="col-sm"></div>
            <div class="col-sm">
                <img src="assets/icons/Zeremonie.png"/>
            </div>
            <div class="col-sm">
                <div>
                    <p class="title">Freie Trauung</p>
                    <p>Um 16:00 Uhr beginnt unsere freie Trauung</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm order-12 order-sm-1">
                <div>
                    <p class="title">Sektempfang</p>
                    <p>Danach stoßen wir gemeinsam mit euch auf unser Eheglück an</p>
                </div>
            </div>
            <div class="col-sm order-1 order-sm-12">
                <img src="assets/icons/Anstossen.png"/>
            </div>
            <div class="col-sm order-12 order-sm-12"></div>
        </div>

        <div class="row">
            <div class="col-sm"></div>
            <div class="col-sm">
                <img src="assets/icons/Ballons.png"/>
            </div>
            <div class="col-sm">
                <div>
                    <p class="title">Abendessen</p>
                    <p>Für leckeres Essen vom Buffet ist gesorgt</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm order-12 order-sm-1">
                <div>
                    <p class="title">Spiel und Spaß</p>
                    <p>Ein paar Spiele und eine magische Überraschung haben wir für euch vorbereitet</p>
                </div>
            </div>
            <div class="col-sm order-1 order-sm-12">
                <img src="assets/icons/Pfeilk.png"/>
            </div>
            <div class="col-sm order-12 order-sm-12"></div>
        </div>

        <div class="row">
            <div class="col-sm">
            </div>
            <div class="col-sm">
                <img src="assets/icons/Torte.png"/>
            </div>
            <div class="col-sm">
                <div>
                    <p class="title">Dessert</p>
                    <p>Neben süßen Kleinigkeiten schneiden wir auch unsere Hochzeitstorte an. Wer kann erkennen welche Hand oben liegt? 😉</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm order-12 order-sm-1">
                <div>
                    <p class="title">Tanz & Musik</p>
                    <p>Nach dem Pflichtprogramm können wir richtig feiern 🥳</p>
                </div>
            </div>
            <div class="col-sm order-1 order-sm-12">
                <img src="assets/icons/Musik.png"/>
            </div>
            <div class="col-sm order-12 order-sm-12"></div>
        </div>
    </div>
</div>
<div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 128"><path fill-opacity="1" d="M0,128L1440,0L1440,0L0,0Z"></path></svg>
</div>
