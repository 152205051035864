<div class="wrapper">
    <div class="container">
        <h2 class="section-title">Location</h2>
        <p>
            Wir feiern unsere Hochzeit in der Bokeria in Mannheim.<br>
            Dort finden sowohl die freie Trauung als auch die anschließende Feier statt.
        </p>
        <p>Mülheimer Straße 6<br> 68219 Mannheim </p>
    </div>
</div>
<div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 128"><path fill-opacity="1" d="M0,128L1440,0L1440,0L0,0Z"></path></svg>
</div>
