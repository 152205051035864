<div class="wrapper">
    <div class="container">
        <h2 class="section-title">Countdown</h2>
        <div class="days">
            <div class="numbers">{{days}}</div>
            <div class="label">Tage</div>
        </div>
        <div class="hours">
            <div class="numbers">{{hours}}</div>
            <div class="label">Stunden</div>
        </div>
        <div class="minutes">
            <div class="numbers">{{minutes}}</div>
            <div class="label">Minuten</div>
        </div>
        <div class="seconds">
            <div class="numbers">{{seconds}}</div>
            <div class="label">Sekunden</div>
        </div>
    </div>
</div>
<div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 128"><path fill-opacity="1" d="M0,128L1440,0L1440,0L0,0Z"></path></svg>
</div>
